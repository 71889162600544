import React from 'react';
import Layout from '../components/layout/index';
import { loadConfig } from '../service/api.js';
import { scrollToElement } from '../util/utils.js';
import Swiper from 'swiper';
import 'swiper/dist/css/swiper.css';
import '../assets/css/index.css';

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: [],
      partners: [],
      homes: [],
      hotels: [],
      villas: [],
      pageElementObj: {},
      popupClassName: 'expand',
    };
  }

  async componentWillMount() {
    const result = await loadConfig();
    const { data } = result;
    const { comments, partners, homes, hotels, villas } = data.indexPage;
    this.setState({
      comments,
      partners,
      homes,
      hotels,
      villas,
    });
  }

  componentDidMount() {
    this.setState({
      pageElementObj: {
        homes: document.querySelector('#homes'),
        hotels: document.querySelector('#hotels'),
        villas: document.querySelector('#villas'),
        rightsAndComments: document.querySelector('#rightsAndComments'),
      },
    });
    this.addScrollEvent();
  }

  componentDidUpdate() {
    new Swiper('.swiper-container', {
      watchSlidesProgress: true,
      slidesPerView: 'auto',
      centeredSlides: true,
      loop: true,
      simulateTouch: false,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
      },
      on: {
        progress: function(progress) {
          for (let i = 0; i < this.slides.length; i++) {
            // debugger
            const slide = this.slides.eq(i);
            const slideProgress = Math.floor(this.slides[i].progress);
            let modify = 1;
            if (Math.abs(slideProgress) > 1) {
              modify = (Math.abs(slideProgress) - 1) * 0.3 + 1;
            }
            const translate = slideProgress * modify * 490 + 'px';
            const scale = 1 - Math.abs(slideProgress) / 3;
            const zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
            slide.transform(`translateX(${translate}) scale(${scale})`);
            slide.css('zIndex', zIndex);
            slide.css('opacity', 1);
            if (Math.abs(slideProgress) > 1) {
              slide.css('opacity', 0);
            }
          }
        },
        setTransition: function(translate) {
          for (let i = 0; i < this.slides.length; i++) {
            this.slides.eq(i).transition(translate);
          }
        },
      },
    });
  }

  //   componentWillUnmount() {
  //     this.removeScrollEvent()
  //   }

  scrollToNextPage = e => {
    const pageId = e.target.dataset.pageId;
    const { pageElementObj } = this.state;
    const element = pageElementObj[pageId];
    scrollToElement(element);
  };

  addScrollEvent = () => {
    window.addEventListener('scroll', this.handleScroll);
  };

  removeScrollEvent = () => {
    window.removeEventListener('scroll', this.handleScroll);
  };

  handleToggle = () => {
    const { popupClassName } = this.state;
    popupClassName === 'close' && this.addScrollEvent();
    this.setState({
      popupClassName: popupClassName === 'expand' ? 'close' : 'expand',
    });
  };

  handleScroll = () => {
    this.setState({
      popupClassName: 'close',
    });
    this.removeScrollEvent();
  };

  render() {
    const {
      homes,
      hotels,
      villas,
      comments,
      partners,
      popupClassName,
    } = this.state;
    return (
      <div className="index-container">
        <Layout>
          {/*banner*/}
          <div className="index-banner">
            <video
              className="banner-video"
              autoPlay={true}
              loop={true}
              muted={true}
              src="https://locals-house-prod.oss-cn-shenzhen.aliyuncs.com/localhomeqy/official-web/banner.mp4"
            />
            <div className="banner-txt-wrap">
              <img
                className="banner-txt"
                src="https://locals-house-prod.oss-cn-shenzhen.aliyuncs.com/localhomeqy/official-web/banner-txt.png"
                alt=""
              />
            </div>
            <i
              className="scroll-btn banner-btn"
              data-page-id="homes"
              onClick={this.scrollToNextPage}
            ></i>
          </div>

          {/*路客HOMES*/}
          <div className="homes-field" id="homes">
            <h1 className="homes-title">路客HOMES</h1>
            <div className="horizon-line"></div>
            <div className="homes-tag">精品民宿 · 一房一设计</div>
            <div className="homes-summary">
              <div>精细化民居分散式管理</div>
              <div>个性化专属一房一设计</div>
            </div>
            {homes.length > 0 && (
              <div className="homes-images">
                <div className="hover-img-field homes-img0">
                  <img className="hover-img" src={homes[0].img} alt="" />
                  <div className="hover-img-content">
                    <div className="middle-content">
                      <div className="hover-name">{homes[0].name}</div>
                      <div className="hover-intro">{homes[0].introduction}</div>
                      <img
                        className="hover-mini-code"
                        src={homes[0].miniProgramCode}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="hover-img-field homes-img1">
                    <img className="hover-img" src={homes[1].img} alt="" />
                    <div className="hover-img-content">
                      <div className="hover-name">{homes[1].name}</div>
                      <div className="hover-intro">{homes[1].introduction}</div>
                      <img
                        className="hover-mini-code"
                        src={homes[1].miniProgramCode}
                        alt=""
                      />
                    </div>
                  </div>

                  <div className="hover-img-field homes-img2">
                    <img className="hover-img" src={homes[2].img} alt="" />
                    <div className="hover-img-content">
                      <div className="hover-name">{homes[2].name}</div>
                      <div className="hover-intro">{homes[2].introduction}</div>
                      <img
                        className="hover-mini-code"
                        src={homes[2].miniProgramCode}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/*hotels*/}
          <div className="hotels-field" id="hotels">
            <img
              className="hotels-title"
              src="https://locals-house-prod.oss-cn-shenzhen.aliyuncs.com/localhomeqy/official-web/pic_hotels.png"
              alt=""
            />
            <div className="hotels-images">
              {hotels.map((hotel, index) => {
                return (
                  <div className="hover-img-field hotels-img" key={index}>
                    <img className="hover-img" src={hotel.img} alt="" />
                    <div className="hover-img-content">
                      <div className="hover-name">{hotel.name}</div>
                      <div className="hover-intro">{hotel.introduction}</div>
                      <img
                        className="hover-mini-code"
                        src={hotel.miniProgramCode}
                        alt=""
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {/*villas*/}
          <div className="villas-field" id="villas">
            <h1 className="villas-title">路客VILLAS</h1>
            <div className="horizon-line"></div>
            <div className="villas-tag">旅居美墅 · 一墅一精彩</div>
            <div className="villas-summary">
              <div>盘活近郊区别墅与团建市场</div>
              <div>打造高品质别墅美学空间</div>
            </div>
            {villas.length > 0 && (
              <div className="villas-images">
                <div>
                  <div className="hover-img-field villas-img0">
                    <img className="hover-img" src={villas[0].img} alt="" />
                    <div className="hover-img-content">
                      <div className="hover-name">{villas[0].name}</div>
                      <div className="hover-intro">
                        {villas[0].introduction}
                      </div>
                      <img
                        className="hover-mini-code"
                        src={villas[0].miniProgramCode}
                        alt=""
                      />
                    </div>
                  </div>

                  <div className="hover-img-field villas-img1">
                    <img className="hover-img" src={villas[1].img} alt="" />
                    <div className="hover-img-content">
                      <div className="hover-name">{villas[1].name}</div>
                      <div className="hover-intro">
                        {villas[1].introduction}
                      </div>
                      <img
                        className="hover-mini-code"
                        src={villas[1].miniProgramCode}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="hover-img-field villas-img2">
                  <img className="hover-img" src={villas[2].img} alt="" />
                  <div className="hover-img-content">
                    <div className="middle-content">
                      <div className="hover-name">{villas[2].name}</div>
                      <div className="hover-intro">
                        {villas[2].introduction}
                      </div>
                      <img
                        className="hover-mini-code"
                        src={villas[2].miniProgramCode}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/*rights and comments*/}
          <div className="rights-and-comments-field" id="rightsAndComments">
            <img
              className="rights"
              src="https://locals-house-prod.oss-cn-shenzhen.aliyuncs.com/localhomeqy/official-web/rights.png"
              alt=""
            />
            <div className="comments-field">
              <h2 className="comments-title">真实口碑点评</h2>
              <div className="horizon-line"></div>
              {comments.length > 0 && (
                <div className="comments-swiper">
                  <div className="swiper-container">
                    <div className="swiper-wrapper">
                      {comments.map((comment, index) => {
                        return (
                          <div
                            className="swiper-slide comment-field"
                            key={index}
                          >
                            <img
                              className="comment-bg"
                              src={comment.bgImg}
                              alt=""
                            />
                            <div className="comment">
                              <img
                                className="comment-user-img"
                                src={comment.userImg}
                                alt=""
                              />
                              <div className="comment-username">
                                {comment.username}
                              </div>
                              <div className="comment-house">
                                <span>入住民宿：</span>
                                <span>{comment.house}</span>
                              </div>
                              <p className="comment-content">
                                {comment.comment}
                              </p>
                              <div className="comment-source">
                                <div>点评来源</div>
                                <img
                                  className="source-icon"
                                  src={comment.source}
                                  alt=""
                                />
                              </div>
                              <a
                                className="comment-detail-btn"
                                href={comment.link}
                                target="_blank"
                              >
                                查看详情
                              </a>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="swiper-pagination"></div>
                  <div className="swiper-button-prev swiper-button-white"></div>
                  <div className="swiper-button-next swiper-button-white"></div>
                </div>
              )}
            </div>
          </div>
          {/*partners*/}
          <div className="partners-field">
            <h2 className="comments-title">合作伙伴</h2>
            <div className="horizon-line"></div>
            <div className="partners">
              {partners.length > 0 &&
                partners.map((partner, index) => {
                  return (
                    <img className="partner" src={partner} alt="" key={index} />
                  );
                })}
            </div>
          </div>
        </Layout>
        {/*popup*/}
        <div className={`${popupClassName} ad-popup`}>
          <div className="ad">
            <img
              className="expand-img"
              src="https://locals-house-prod.oss-cn-shenzhen.aliyuncs.com/localhomeqy/official-web/tanchuang2.png"
              alt="popup"
            />
            <img
              className="close-img"
              src="https://locals-house-prod.oss-cn-shenzhen.aliyuncs.com/localhomeqy/official-web/tanchuang1.png"
              alt="popup"
              onClick={this.handleToggle}
            />
            <div className="toggle" onClick={this.handleToggle}></div>
          </div>
          <div className="mask"></div>
        </div>
      </div>
    );
  }
}
